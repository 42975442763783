import { def, reactive as ref, html } from './lit'

const prevent = fn => e => {
  if (e) e.preventDefault()
  fn()
}

const userAgent = navigator.userAgent || navigator.vendor || window.opera
const is_mobile =
  /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
    userAgent
  ) ||
  /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
    userAgent.substr(0, 4)
  )

const default_state = {
  year: null,
  make_id: null,
  model_id: null,
  heading: null
}

const get_parts_state = () => {
  try {
    return JSON.parse(localStorage.getItem('seeker-parts-finder-2025-state')) || { ...default_state }
  } catch (e) {
    return { ...default_state }
  }
}
const set_parts_state = (state, fn_name) => {
  // console.log(fn_name, state)
  try {
    localStorage.setItem(
      'seeker-parts-finder-2025-state',
      JSON.stringify({
        year: state.year,
        make_id: state.make_id,
        model_id: state.model_id,
        heading: state.heading
      })
    )
  } catch (e) {
    console.error(e)
  }
}

const script = document.currentScript
const shadow = !script.hasAttribute('data-token')
const jwt = script.getAttribute('data-token') || script.getAttribute('src').split('=')[1].split('&')[0]
const auth_fetch = url => {
  return fetch(url, {
    headers: { Authorization: `Bearer ${jwt}` }
  })
}

// https://lit-html.polymer-project.org/guide/writing-templates
def('seeker-parts-finder', { shadow, props: ['stylesheet'] }, props => {
  // data
  let years = null
  let makes = null
  let makesbyid = null
  let models = null
  let modelsbyid = null
  let parts = null
  let partsbyheading = null
  let parts_headings = null
  let cart = []
  let cart_item_latest = null
  let tyre_disclaimer_headers = new Set()
  let oil_disclaimer_headers = new Set()

  const search = ref({
    make: '',
    model: ''
  })

  // reactive state
  const state = ref({
    state: !shadow ? 'minimised' : 'default',
    ...get_parts_state()
  })

  const load_years = async () => {
    // console.log(`Getting years for make ${state.make_id}`)
    const res = state.make_id
      ? await auth_fetch(`${process.env.SEEKER_API}/v1/years/make/${state.make_id}/`)
      : await auth_fetch(`${process.env.SEEKER_API}/v1/years`)
    years = await res.json()
  }
  const load_makes = async () => {
    // console.log(`Getting makes for year ${state.year}`)
    const res = state.year
      ? await auth_fetch(`${process.env.SEEKER_API}/v1/makes/year/${state.year}/`)
      : await auth_fetch(`${process.env.SEEKER_API}/v1/makes`)
    makes = await res.json()
    makesbyid = new Map(makes.map(m => [m.id, m]))
  }

  const load_models = async () => {
    if (state.make_id == null || state.year == null) {
      models = null
      modelsbyid = null
      return
    }
    // console.log(`Getting models for make ${state.make_id} and year ${state.year}`)
    const res = await auth_fetch(`${process.env.SEEKER_API}/v1/models/year/${state.year}/make/${state.make_id}/`)
    if (res.status === 429) {
      state.state = 'error'
      throw 'Too many requests'
    }
    if (res.status === 403) {
      state.state = 'error'
      throw 'Forbidden'
    }
    models = await res.json()
    const collator = new Intl.Collator(undefined, {
      numeric: true,
      sensitivity: 'base'
    })
    models = models
      .sort((a, b) => (a.cc_rating - b.cc_rating ? 1 : -1))
      .sort((a, b) => collator.compare(a.name, b.name))
    modelsbyid = new Map(models.map(m => [m.id, m]))
  }
  const load_fitments = async () => {
    if (state.model_id == null) {
      parts = null
      partsbyheading = null
      parts_headings = null
      return
    }
    const fake = 'test'
    // console.log(`Getting fitments for model ${state.model_id}`)
    const res = await auth_fetch(`${process.env.SEEKER_API}/v1/fitments/model/${state.model_id}/`)
    if (res.status === 429) {
      state.state = 'error'
      throw 'Too many requests'
    }
    if (res.status === 403) {
      state.state = 'error'
      throw 'Forbidden'
    }
    parts = await res.json()
    partsbyheading = parts.reduce((map, p) => {
      if (p.tyre_disclaimer) tyre_disclaimer_headers.add(p.heading_parent)
      if (p.oil_disclaimer) oil_disclaimer_headers.add(p.heading_parent)
      if (!map.has(p.heading_parent)) map.set(p.heading_parent, new Map())
      const items = map.get(p.heading_parent)
      if (!items.has(p.heading)) items.set(p.heading, [])
      items.get(p.heading).push(p)
      return map
    }, new Map())
    parts_headings = Array.from(partsbyheading.keys()).sort()
  }
  const maximise = prevent(async () => {
    try {
      state.state = 'loading'
      await load_years()
      await load_makes()
      await load_models()
      await load_fitments()
      state.state = 'default'
    } catch (e) {
      console.error(e)
      state.state = 'error'
    }
  })
  const minimise = prevent(async () => {
    const parts_state = get_parts_state()
    if (parts_state.year || parts_state.make) state.state = !shadow ? 'minimised-continue' : 'default-continue'
    else state.state = !shadow ? 'minimised' : 'default'
  })
  const show_select_year = prevent(async () => {
    state.state = 'loading'
    try {
      await load_years()
      state.model_id = null
      state.heading = null
      state.state = 'select_year'
      set_parts_state(state, 'show_select_year')
    } catch (e) {
      console.error(e)
      state.state = 'default'
    }
  })
  const select_year = async year => {
    state.year = year
    if (state.make_id == null) show_select_make()
    else if (state.model_id == null) show_select_model()
  }
  const show_select_make = prevent(async () => {
    state.state = 'loading'
    try {
      await load_makes()
      state.model_id = null
      state.heading = null
      state.state = 'select_make'
      set_parts_state(state, 'show_select_make')
    } catch (e) {
      console.error(e)
      state.state = 'default'
    }
  })
  const select_make = async make_id => {
    state.make_id = make_id
    search.make = ''
    set_parts_state(state, 'select_make')
    if (state.year == null) show_select_year()
    else if (state.model_id == null) show_select_model()
  }
  const show_select_model = prevent(async () => {
    state.state = 'loading'
    try {
      await load_models()
      state.heading = null
      state.state = 'select_model'
    } catch (e) {
      console.error(e)
      state.state = 'default'
    }
  })
  const select_model = async model_id => {
    state.model_id = model_id
    search.model = ''
    state.state = 'loading'
    set_parts_state(state, 'select_model')
    try {
      await load_fitments()
      state.state = 'default'
    } catch (e) {
      console.error(e)
      state.state = 'default'
    }
  }
  const select_all_headings = async headings => {
    state.heading = headings
    set_parts_state(state, 'select_all_headings')
  }
  const select_heading = async heading => {
    state.heading = heading
    set_parts_state(state, 'select_heading')
  }
  const back = () => {
    state.heading = null
    set_parts_state(state, 'back')
  }
  const close = prevent(() => {
    state.state = 'default'
  })
  const clear = prevent(async () => {
    const previous_state = state.state
    state.state = 'loading'
    state.year = null
    state.make_id = null
    state.model_id = null
    state.heading = null
    search.make = ''
    search.model = ''
    await load_years()
    await load_makes()
    set_parts_state(state, 'clear')
    state.state = previous_state
  })

  const change_state = state_name => {
    state.state = state_name
  }

  const set_make_search = value => {
    search.make = value
  }

  const set_model_search = value => {
    search.model = value
  }

  const includes_query = (value, search_term) => {
    return value.toLowerCase().trim().includes(search_term.toLowerCase().trim())
  }

  let timer
  const start_timer = () => {
    timer = setTimeout(() => {
      state.cart_state = 'default'
    }, 7000)
  }

  const shopify_cart_update = async (id, qty) => {
    clearTimeout(timer)
    state.cart_state = 'loading'
    const protocol = window.location.protocol + '//'
    const host = window.location.host
    const add_to_cart = await fetch(`${protocol + host}/cart/update.js`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include'
      },
      body: JSON.stringify({
        updates: {
          [id]: qty
        }
      })
    })
    try {
      const cart_res = await add_to_cart.json()
      if (add_to_cart.status !== 200) {
        console.log(`Could not add product to cart.`)
      }
      cart = cart_res.items
      cart_item_latest = cart.findIndex(p => (p.id = id))
    } catch (e) {
      console.error(e)
    }
    if (cart.length) {
      // auto hide cart notifiation after 7 seconds
      state.cart_state = 'notify'
      start_timer()
    }
  }

  // handle remeber config
  ;(async () => {
    if (shadow) {
      state.state = 'loading'
      await load_years()
      await load_makes()
      await load_models()
      await load_fitments()
    }
    const parts_state = get_parts_state()
    if (parts_state.year || parts_state.make) state.state = !shadow ? 'minimised-continue' : 'default-continue'
    else state.state = !shadow ? 'minimised' : 'default'
  })()

  return () => {
    const format_available_quantity = qty => {
      if (!parseInt(qty)) qty = 0
      const quantity = qty > 10 ? 10 : qty

      return html` <div>
        ${quantity < 10
          ? html`<h3 class="quantity"><span class="qty-title">Qty: </span>${quantity}</h3>`
          : html`<h3 class="quantity"><span class="qty-title">Qty: </span>${quantity}+</h3>`}
      </div>`
    }

    const format_pricing = (price, promo) => {
      if (!price) return html`&nbsp;`
      const price_chunks = price.split('.')
      const price_whole = price_chunks[0]
      const price_decimal = price_chunks.length == 1 ? '00' : price_chunks[1]
      if (!parseInt(promo)) {
        return html`
          <div class="pricing">
            <div class="price">
              <span class="whole">${price_whole}</span>
              <span class="decimal">${price_decimal}</span>
            </div>
          </div>
        `
      } else {
        const promo_chunks = promo.split('.')
        const promo_whole = promo_chunks[0]
        const promo_decimal = promo_chunks.length == 1 ? '00' : promo_chunks[1]

        return html`
          <div class="pricing">
            <div class="original">
              <span class="whole">${price_whole}</span>
              <span class="decimal">${price_decimal}</span>
            </div>
            <div class="price">
              <span class="whole">${promo_whole}</span>
              <span class="decimal">${promo_decimal}</span>
            </div>
            <div
              class="sale"
              style="width:100%;background-color: grey;color:white;font-weight:bold;text-align:center;padding:5px;"
            >
              SALE
            </div>
          </div>
        `
      }
    }

    const content = () => {
      if (state.state == 'minimised')
        return html`
          <div class="wnpf wnpf-minimised">
            <a class="main-heading" href="#" @click="${maximise}"></a>
          </div>
        `
      else if (state.state == 'minimised-continue')
        return html`
          <div class="wnpf wnpf-minimised">
            <a class="nb continue ${shadow && 'btn'}" href="#" @click="${maximise}"></a>
          </div>
        `
      else if (state.state == 'loading')
        return html`
          <div class="wnpf wnpf-loading">
            <nav>
              <h1 class="main-heading"></h1>
              <span class="loading message" id="parts-finder-loading"></span>
            </nav>
          </div>
        `
      else if (state.state == 'default-continue')
        return html`
          <div class="wnpf wnpf-default">
            <nav>
              <h1 class="main-heading"></h1>
              <a class="nb continue btn" @click="${prevent(() => change_state('default'))}" href="#"> </a>
            </nav>
          </div>
        `
      else if (state.state == 'error') {
        localStorage.removeItem('seeker-parts-finder-state')
        return html`
          <div class="wnpf wnpf-error">
            <nav>
              <h1 class="main-heading"></h1>
              <span class="loading message" id="parts-finder-error"></span>
            </nav>
          </div>
        `
      } else if (state.state == 'default')
        return html`
          <div class="wnpf wnpf-default">
            <nav>
              <h1 class="main-heading"></h1>
              <div class="options">
                ${state.year != null
                  ? html`<a class="change btn" @click="${show_select_year}" href="#"
                      ><span>YEAR: ${state.year}</span></a
                    >`
                  : html`<a class="btn" @click="${show_select_year}" href="#"><span>Choose Year</span></a>`}
                ${state.make_id != null
                  ? html`<a class="change btn" @click="${show_select_make}" href="#"
                      ><span>MAKE: ${makesbyid.get(state.make_id).name}</span></a
                    >`
                  : html`<a class="btn" @click="${show_select_make}" href="#"><span>Choose Make</span></a>`}
                ${state.model_id != null
                  ? html`<a class="change btn" @click="${show_select_model}" href="#"
                      ><span>MODEL: ${modelsbyid.get(state.model_id).name}</span></a
                    >`
                  : state.make_id != null && state.year != null
                  ? html`<a class="btn" @click="${show_select_model}" href="#"><span>Choose Model</span></a>`
                  : null}
              </div>
              <div class="actions">
                ${state.make_id || state.year
                  ? html`<a class="nb clearfilters btn" title="Clear selected filters" @click="${clear}" href="#"
                      >Clear ✕</a
                    >`
                  : null}
                ${!shadow
                  ? html`<a class="nb min btn" title="Minimise" @click="${minimise}" href="#"><span>↘</span></a>`
                  : null}
              </div>
            </nav>
            ${state.heading
              ? html`
                  <hr />
                  <a class="back btn" href="#" @click="${prevent(() => back())}">← Back</a>
                  ${!Array.isArray(state.heading)
                    ? html` <article class="parts">
                        ${partsbyheading.has(state.heading) && partsbyheading.get(state.heading).size > 0
                          ? Array.from(partsbyheading.get(state.heading).keys())
                              .sort()
                              .map(
                                heading => html`
                      <section>
                        <div class="heading">
                          <h2>${heading}</h2>
                        </div>
                        ${partsbyheading
                          .get(state.heading)
                          .get(heading)
                          .map(part => {
                            return html` <span class="part">
                              <div class="layout">
                                <div class="section-one">
                                  ${part.image_url
                                    ? html`<div class="item-image">
                                        <a href="${part.product_url}"><img src="${part.image_url}" /></a>
                                      </div>`
                                    : html`<div class="item-image">&nbsp;</div>`}
                                  <div class="item-title">
                                    <h3 style="margin-bottom: 0;margin-top: 0;font-weight:bold;">
                                      <a href="${part.product_url}">${part.name}</a>
                                    </h3>
                                  </div>
                                </div>
                                <div class="section-two">
                                  <div class="item-quantity">
                                    <a href="${part.product_url}">
                                      ${format_available_quantity(part.quantity_available)}
                                    </a>
                                  </div>
                                  <div class="item-pricing">
                                    <a href="${part.product_url}">
                                      ${format_pricing(part.default_price, part.promotion_price)}
                                    </a>
                                  </div>
                                </div>
                                ${!shadow
                                  ? ''
                                  : html`<div
                                      class="section-three seeker-cart-ui ${part.quantity_available > 0
                                        ? 'visible'
                                        : 'hidden'}"
                                    >
                                      <div>
                                        <input
                                          @change="${e => {
                                            if (e.target.value > part.quantity_available) {
                                              e.target.value = part.quantity_available
                                            }
                                            const cart_item_index = cart.findIndex(p => p.id == part.id)
                                            if (cart_item_index >= 0)
                                              cart[cart_item_index].next_quantity = e.target.value
                                            else {
                                              cart.push({
                                                id: part.id,
                                                next_quantity: e.target.value
                                              })
                                            }
                                          }}}"
                                          name="qty"
                                          type="number"
                                          value="${part.quantity_available > 0 ? 1 : 0}"
                                          min="${part.quantity_available > 0 ? 1 : 0}"
                                          max="${part.quantity_available}"
                                        />
                                        <button
                                          @click="${prevent(() => {
                                            const cart_item_index = cart.findIndex(p => p.id == part.id)
                                            shopify_cart_update(part.id, cart[cart_item_index]?.next_quantity || 1)
                                          })}"
                                          name="add"
                                        >
                                          Add to Cart
                                        </button>
                                      </div>
                                    </div>`}
                              </div>
                            </span>`
                          })}
                      </div>`
                              )
                          : html`<i>No parts found.</i>`}
                      </article>`
                    : html`<article class="parts">
                        ${Array.isArray(state.heading) &&
                        html`
                          ${state.heading.map(
                            heading => html`
                              ${Array.from(partsbyheading.get(heading).keys())
                                .sort()
                                .map(
                                  subheading => html`
                                    <section>
                                      <div class="heading">
                                        <h2>${heading === subheading ? heading : heading + ' - ' + subheading}</h2>
                                      </div>
                                      ${tyre_disclaimer_headers.has(heading)
                                        ? html`<div class="disclaimer">
                                            <p>
                                              It is not recommended to fit a mixture of bias & radial tyres to the same
                                              motorcycle. For optimal safety and performance, it is best to stick with
                                              the tyre type originally specified for your motorcycle. If you have any
                                              doubts, consult a professional mechanic, or refer to your motorcycle’s
                                              manual.
                                            </p>
                                          </div>`
                                        : ''}
                                      ${oil_disclaimer_headers.has(heading)
                                        ? html`<div class="disclaimer">
                                            <p>
                                              Every effort has been made to ensure the accuracy of this oil fitment
                                              recommendation. However, we advise always consulting your motorcycle's
                                              service manual for exact oil requirements.
                                            </p>
                                          </div>`
                                        : ''}
                                      ${partsbyheading
                                        .get(heading)
                                        .get(subheading)
                                        .map(part => {
                                          return html`<span class="part">
                                            <div class="layout">
                                              <div class="section-one">
                                                ${
                                                  part.image_url
                                                    ? html`<div class="item-image">
                                                        <a href="${part.product_url}"
                                                          ><img src="${part.image_url}"
                                                        /></a>
                                                      </div>`
                                                    : html`<div class="item-image">&nbsp;</div>`
                                                }
                                                <div class="item-title">
                                                  <h3
                                                    style="margin-bottom: 0;margin-top: 0;font-weight:bold;"
                                                  >
                                                  <a href="${part.product_url}">${part.name}</a>
                                                  </h3>
                                                </div>
                                              </div>
                                              <div class="section-two">
                                                <div class="item-quantity">
                                                  <a href="${part.product_url}">
                                                  ${format_available_quantity(part.quantity_available)}
                                                  </a>
                                                </div>
                                                <div class="item-pricing">
                                                  <a href="${part.product_url}">
                                                    ${format_pricing(part.default_price, part.promotion_price)}
                                                  </a>
                                                </div>
                                              </div>
                                              ${
                                                !shadow
                                                  ? ''
                                                  : html`<div
                                                      class="section-three seeker-cart-ui ${part.quantity_available > 0
                                                        ? 'visible'
                                                        : 'hidden'}"
                                                    >
                                                      <div>
                                                        <input
                                                          @change="${e => {
                                                            if (e.target.value > part.quantity_available) {
                                                              e.target.value = part.quantity_available
                                                            }
                                                            const cart_item_index = cart.findIndex(p => p.id == part.id)
                                                            if (cart_item_index >= 0)
                                                              cart[cart_item_index].next_quantity = e.target.value
                                                            else {
                                                              cart.push({
                                                                id: part.id,
                                                                next_quantity: e.target.value
                                                              })
                                                            }
                                                          }}}"
                                                          name="qty"
                                                          type="number"
                                                          value="${part.quantity_available > 0 ? 1 : 0}"
                                                          min="${part.quantity_available > 0 ? 1 : 0}"
                                                          max="${part.quantity_available}"
                                                        />
                                                        <button
                                                          @click="${prevent(() => {
                                                            const cart_item_index = cart.findIndex(p => p.id == part.id)
                                                            shopify_cart_update(
                                                              part.id,
                                                              cart[cart_item_index]?.next_quantity || 1
                                                            )
                                                          })}"
                                                          name="add"
                                                        >
                                                          Add to Cart
                                                        </button>
                                                      </div>
                                                    </div>`
                                              }
                                              </div>
                                            </div>
                                            </span>`
                                        })}
                                    </section>
                                  `
                                )}
                            `
                          )}
                        `}
                      </article>`}
                `
              : state.model_id
              ? html` <hr />
                  <article>
                    <a class="select btn" @click="${prevent(() => select_all_headings(parts_headings))}" href="#"
                      ><b>View All</b></a
                    >
                    ${parts_headings.map(
                      heading => html` <a class="select btn" @click="${prevent(() => select_heading(heading))}" href="#"
                        >${heading}</a
                      >`
                    )}
                  </article>`
              : html` <article>
                  <p class="message" id="parts-finder-help"></p>
                </article>`}
          </div>
        `
      else if (state.state == 'select_year')
        return html`
          <div class="wnpf wnpf-default">
            <nav>
              <h1>Choose Year</h1>
              <div class="options">
                ${state.make_id
                  ? html`<a class="change btn" @click="${show_select_make}" href="#"
                      ><span>MAKE: ${makesbyid.get(state.make_id).name}</span></a
                    >`
                  : null}
              </div>
              <div class="actions">
                ${state.make_id
                  ? html`<a class="nb clearfilters btn" title="Clear selected filters" @click="${clear}" href="#"
                      >Clear ✕</a
                    >`
                  : null}
                <a class="close" @click="${close}" href="#">✕</a>
              </div>
            </nav>
            <hr />
            <article>
              ${years.map(
                year => html` <a class="select btn" @click="${prevent(() => select_year(year))}" href="#"
                  ><span>${year}</span></a
                >`
              )}
            </article>
          </div>
        `
      else if (state.state == 'select_make')
        return html`
          <div class="wnpf wnpf-default">
            <nav>
              <h1>Choose Make</h1>
              <div class="options">
                ${state.year
                  ? html`<a class="change btn" @click="${show_select_year}" href="#"
                      ><span>YEAR: ${state.year}</span></a
                    >`
                  : null}
              </div>
              <div class="actions">
                ${state.year
                  ? html`<a class="nb clearfilters btn" title="Clear selected filters" @click="${clear}" href="#"
                      >Clear ✕</a
                    >`
                  : null}
                <a class="close" @click="${close}" href="#">✕</a>
              </div>
            </nav>
            <hr />
            <input
              class="search"
              type="text"
              id="make-filter"
              name="make-filter"
              placeholder="Type here to search for your make..."
              v-bind:value="${search.make}"
              @input="${e => set_make_search(e.target.value)}"
            />
            <hr />
            ${makes && makes.filter(make => make.is_popular).filter(m => includes_query(m.name, search.make)).length > 0
              ? html` <h3>POPULAR MAKES</h3>
                  <article>
                    ${makes
                      .filter(make => make.is_popular)
                      .filter(m => includes_query(m.name, search.make))
                      .map(
                        make => html` <a class="select btn" @click="${prevent(() => select_make(make.id))}" href="#"
                          ><span>${make.name}</span></a
                        >`
                      )}
                  </article>`
              : null}
            ${(makes &&
              makes.filter(make => make.is_popular).filter(m => includes_query(m.name, search.make)).length === 0) ||
            makes.filter(m => includes_query(m.name, search.make)).length === 0
              ? null
              : html`<hr />`}
            ${makes && makes.filter(m => includes_query(m.name, search.make)).length > 0
              ? html` <article>
                  ${makes
                    .filter(m => includes_query(m.name, search.make))
                    .map(
                      make => html` <a class="select btn" @click="${prevent(() => select_make(make.id))}" href="#"
                        ><span>${make.name}</span></a
                      >`
                    )}
                </article>`
              : null}
            ${makes && makes.filter(m => includes_query(m.name, search.make)).length === 0
              ? html` <article>
                  <i>No make found matching your search.</i>
                </article>`
              : null}
          </div>
        `
      else if (state.state == 'select_model')
        return html`
        <div class="wnpf wnpf-default">
          <nav>
            <h1>Choose Model</h1>
            <div class="options">
              <a class="change btn" @click="${show_select_year}" href="#"><span>YEAR: ${state.year}</span></a>
              <a class="change btn" @click="${show_select_make}" href="#"><span>MAKE: ${
          makesbyid.get(state.make_id).name
        }</span></a>
            </div>
            <div class="actions">
              <a class="close" @click="${close}" href="#">✕</a>
            </div>
          </nav>
          <hr />
            <input class="search" type="text" id="model-filter" name="model-filter" placeholder="Type here to search for your model..." v-bind:value="${
              search.model
            }" @input="${e => set_model_search(e.target.value)}">
          <hr />
          <article>
          ${
            search.model === ''
              ? html`${(models || []).map(
                  model => html` <a class="select btn" @click="${prevent(() => select_model(model.id))}" href="#"
                    ><span>${model.name}</span></a
                  >`
                )}`
              : html`${models.filter(m => includes_query(m.name, search.model)).length > 0
                  ? html`${models
                      .filter(m => includes_query(m.name, search.model))
                      .map(
                        model => html` <a class="select btn" @click="${prevent(() => select_model(model.id))}" href="#"
                          ><span>${model.name}</span></a
                        >`
                      )}`
                  : html`<i>No makes matching your search.</i>`}`
          }
          ${
            // Force notification to visible.
            state.cart_state == 'notify'
              ? html`<div class="cart-notification">
                    <div class="countdown"></div>
                    <span>${cart[cart_item_latest].quantity}x</span>
                    <span class="varient-title">${cart[cart_item_latest].variant_title}</span>
                    <span>added to cart.</span>
                    <span class='close' @click="${prevent(() => (state.cart_state = 'default'))}">close<span>
                  </div>`
              : null
          }
        </div>
      `
      return html`<div>Whoops</div>`
    }

    const working_state = ['default', 'select_year', 'select_make', 'select_model']

    return html`
      ${props.stylesheet ? html`<link rel="stylesheet" href="${props.stylesheet}" type="text/css" />` : null}
      <style>
        seeker-parts-finder-root {
        }
        ${!shadow
            ? html`
                seeker-parts-finder { z-index: 2000; position: fixed; right: 0; bottom: 0; max-width: 787px; max-height:
                800px; max-width: min(787px, 100vw); height: 100%; max-height: min(800px, 100vh); font-family: Muli,
                Helvetica, Arial, sans-serif; display: flex; align-items: end; justify-content: end; padding: 10px;
                ${working_state.includes(state.state)
                  ? 'max-height: min(800px, 100vh);'
                  : 'max-height: min(100px, 100vh);'}}
              `
            : html` seeker-parts-finder { padding: 10px; } `}
          .wnpf {
          background: white;
          border: 1px solid #222;
          padding: 10px 15px;
          display: flex;
          flex-direction: column;
          max-width: 767px;
          max-width: min(767px, 100vw);
        }
        .disclaimer {
          background: red;
          color: black;
          font-weight: bold;
          padding: 10px;
        }
        * {
          box-sizing: border-box;
        }
        .wnpf .nb {
          white-space: nowrap;
        }
        .wnpf nav {
          flex-shrink: 0;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 10px;
          min-height: 35px;
          flex-direction: row;
        }
        .wnpf .loading {
          margin-left: auto;
          text-transform: uppercase;
        }
        .wnpf nav > * {
          margin: 0 0px;
        }
        .wnpf nav .min {
          margin-right: 0;
        }
        .wnpf hr {
          flex-shrink: 0;
          border-color: #222;
          margin: 10px 0;
        }
        .wnpf h1,
        .wnpf h2 {
          text-transform: uppercase;
          margin-bottom: 0;
          font-family: Muli, Helvetica, Arial, sans-serif;
        }
        .wnpf h1 {
          font-size: 22px;
        }
        .wnpf .main-heading {
          font-weight: bold;
          flex: 1;
        }
        .wnpf .main-heading::before {
          content: 'Find Parts';
        }
        .wnpf .continue::before {
          content: 'Continue...';
        }
        .wnpf .selection-dropdowns {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
          align-items: center;
        }
        .wnpf button[name='edit-search'] {
          display: none;
        }
        .wnpf h2 {
          font-size: 18px;
          font-weight: bold;
        }
        .wnpf .heading {
          text-align: center;
        }
        .wnpf h3 {
          font-family: Muli, Helvetica, Arial, sans-serif;
        }
        .wnpf nav h1 {
          margin-right: 0px;
        }
        .wnpf nav .options {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          margin: -5px;
        }
        .wnpf nav .options > * {
          margin: 5px;
        }
        .wnpf .actions {
          margin-left: auto;
          margin-right: 0;
          display: flex;
          align-items: center;
        }
        .wnpf .actions > * {
          margin-left: 10px;
        }
        .wnpf .close {
          opacity: 1;
          margin-left: 20px;
          font-weight: bold;
        }
        .wnpf .btn {
          text-transform: uppercase;
          border-color: #222;
        }
        .wnpf .btn.change,
        .wnpf .btn.select {
          border-radius: 100px;
        }
        .wnpf .btn.change {
          padding-left: 17px;
          padding-right: 17px;
        }
        .wnpf .btn.select {
          margin: 5px 2px;
          white-space: nowrap;
        }
        .wnpf .btn.back {
          flex-shrink: 0;
          margin-top: 5px;
          align-self: flex-start;
        }
        .wnpf article {
          overflow: auto;
          margin: 0 -15px;
          padding: 0 15px;
        }
        .wnpf nav.search-options {
          position: sticky;
          top: -1px;
          margin-top: 1px !important;
          background: white;
        }
        .wnpf nav[stuck] {
          padding: 15px 0px;
          left: 0;
          right: 0;
          background-color: #fff;
          border-bottom: 1px solid #ccc;
        }
        .wnpf-default {
          max-height: 100%;
        }
        .wnpf-default article.parts {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          grid-gap: 15px;
          overflow: auto;
        }
        .wnpf-default article.parts > * {
          display: block;
          border: 1px solid #222;
          padding: 10px;
        }
        .wnpf .part {
          display: block;
          margin-top: 10px;
          padding-top: 10px;
          border-top: 1px solid #222;
        }
        .wnpf .category.collapse section {
          display: none;
        }
        .wnpf .category.expand section {
          display: block;
        }
        .wnpf .category-heading {
          text-align: center;
          cursor: pointer;
          margin: -10px;
          padding: 10px;
        }
        .wnpf .category.expand .category-heading {
          background: #eee;
          margin-bottom: 10px;
        }
        .wnpf .category-subheading {
          text-transform: uppercase;
          margin-bottom: 0;
          font-family: Muli, Helvetica, Arial, sans-serif;
          font-size: 16px;
          font-weight: bold;
        }
        .wnpf .part .layout {
          display: flex;
          flex-direction: row;
        }
        @media (max-width: 600px) {
          seeker-parts-finder {
            height: auto;
            padding-top: 20px;
          }
          .wnpf-default {
            max-height: 97vh;
          }
          .wnpf .part .layout {
            display: block;
          }
          .wnpf nav[stuck] button[name='edit-search'] {
            display: block !important;
          }
          .wnpf nav:not([stuck]) button[name='edit-search'] {
            display: none;
          }
          .wnpf nav[stuck] .selection-dropdowns .select,
          .wnpf nav[stuck] .selection-dropdowns button[name='search'] {
            display: none;
          }
          .wnpf nav[stuck] .selection-dropdowns.visible .select,
          .wnpf nav[stuck] .selection-dropdowns.visible button[name='search'] {
            display: block;
          }
        }
        .wnpf .part .section-one {
          display: grid;
          grid-template-columns: 27% 73%;
          grid-column-gap: 10px;
          width: calc(75% - 10px);
        }
        .wnpf .part .section-two {
          display: grid;
          grid-template-columns: 40% 60%;
          grid-column-gap: 10px;
          width: calc(25% - 10px);
        }
        .wnpf .part .section-three {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          box-sizing: border-box;
        }
        .wnpf .part .section-three > div {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
        }
        .wnpf div.select {
          display: flex;
          position: relative;
          background: #fff;
        }
        .wnpf div.select:after {
          content: '▾';
          position: absolute;
          right: 5px;
          z-index: 0;
        }
        .wnpf select {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          box-sizing: border-box;
          background: transparent;
          border: 1px solid #999;
          padding: 5px 15px 5px 5px;
          border-radius: 0px;
          z-index: 1;
        }
        .wnpf select:hover:enabled {
          border: 1px solid #222;
          cursor: pointer;
        }
        .wnpf select:focus {
          outline: none;
        }
        input[type='number']::-webkit-inner-spin-button,
        input[type='number']::-webkit-outer-spin-button {
          opacity: 1;
        }
        .wnpf input[name='qty'] {
          box-sizing: border-box;
          background: #fff;
          border: 1px solid #ccc;
          padding: 5px;
        }
        .wnpf input[name='qty']:focus {
          outline: none;
          border: 1px solid #999;
        }
        .wnpf button[name='add'],
        .wnpf button[name='search'],
        .wnpf button[name='edit-search'] {
          box-sizing: border-box;
          background: #fff;
          border: 1px solid #999;
          text-transform: uppercase;
          font-weight: bold;
          padding: 5px;
        }
        .wnpf button[name='edit-search'] {
          display: none;
        }
        .wnpf button[name='add']:hover,
        .wnpf button[name='search']:hover,
        .wnpf button[name='edit-search']:hover {
          border: 1px solid #222;
          cursor: pointer;
        }
        .wnpf .cart-notification {
          position: relative;
          display: flex;
          gap: 10px;
          justify-content: space-between;
          box-sizing: border-box;
          position: sticky;
          right: 0;
          bottom: 0;
          background: grey;
          color: white;
          margin: 20px 0px 0px 0px;
          padding: 10px;
          font-size: larger;
        }
        .wnpf .cart-notification .countdown {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 2px;
          width: 100%;
          background: white;
          animation-name: countdown;
          animation-duration: 7s;
          animation-timing-function: linear;
        }
        @keyframes countdown {
          from {
            width: 100%;
          }
          to {
            width: 0%;
          }
        }
        .wnpf .cart-notification .close {
          text-transform: uppercase;
          text-decoration: underline;
          cursor: pointer;
        }
        .wnpf .cart-notification .varient-title {
          flex-grow: 0;
          font-weight: bold;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .wnpf .cart-notification .varient-title + span {
          flex-grow: 1;
          flex-shrink: 0;
        }
        .wnpf .hidden {
          visibility: hidden;
        }
        .wnpf .sale {
          box-sizing: border-box;
          width: 100%;
          background-color: grey;
          color: white;
          font-weight: bold;
          text-align: center;
          padding: 5px;
        }
        .wnpf button.toggle-collapse-all {
          box-sizing: border-box;
          background: #fff;
          border: 1px solid #999;
          text-transform: uppercase;
          font-weight: bold;
          padding: 5px;
          align-self: flex-start;
        }
        .wnpf button.toggle-collapse-all:hover {
          border: 1px solid #222;
          cursor: pointer;
        }
        .wnpf .part .layout .section-one .item-image {
          display: flex;
          align-items: center;
          position: relative;
        }
        .wnpf .part .layout .section-one .item-image:before {
          content: '';
          position: absolute;
          right: 5px;
          bottom: 10%;
          height: 80%;
          width: 1px;
          border-right: 1px solid black;
        }
        .wnpf .part .layout .section-one .item-title {
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 15px;
        }
        .wnpf .part .layout .section-one .item-title:before {
          content: '';
          position: absolute;
          right: 5px;
          bottom: 10%;
          height: 80%;
          width: 1px;
          border-right: 1px solid black;
        }
        .wnpf .part .layout .section-two .item-quantity {
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          justify-content: center;
        }
        .wnpf .part .layout .section-two .item-quantity:before {
          content: '';
          position: absolute;
          right: 5px;
          bottom: 10%;
          height: 80%;
          width: 1px;
          border-right: 1px solid black;
        }
        .wnpf .part .layout .section-two .item-pricing {
          display: flex;
          align-items: center;
          position: relative;
          justify-content: center;
        }
        @media (max-width: 600px) {
          .wnpf .part .section-one {
            display: grid;
            grid-template-columns: 25% 75%;
            grid-column-gap: 10px;
            width: calc(100% - 10px);
          }
          .wnpf .part .layout .section-one .item-title {
            align-items: center;
            border-right: none;
          }
          .wnpf .part .layout .section-one .item-title:before {
            content: none;
          }
          .wnpf .part .section-two {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
            width: calc(100% - 10px);
            margin-top: 10px;
          }
          .wnpf .part .layout .section-two .item-quantity {
            justify-content: flex-start;
            border-right: none;
            margin-left: 10px;
          }
          .wnpf .part .layout .section-two .item-quantity:before {
            content: none;
          }
          .wnpf .part .layout .section-two .item-pricing {
            justify-content: flex-end;
          }
          .wnpf .part .layout .section-two .item-pricing:before {
            content: none;
          }
          .wnpf .part .section-three {
            padding: 10px !important;
          }
          .wnpf .hidden {
            display: none !important;
          }
        }
        .wnpf .details {
          text-align: right;
        }
        .wnpf .pricing {
          display: flex;
          flex-direction: column;
        }
        @media (max-width: 600px) {
          .wnpf .pricing {
            flex-direction: row;
          }
        }
        .wnpf .quantity {
          font-weight: bold;
          text-align: center;
        }
        .wnpf .qty-title {
          display: block;
        }
        @media (max-width: 600px) {
          .wnpf .qty-title {
            display: inline-block;
          }
          .wnpf .item-quantity {
            flex-direction: row !important;
          }
        }
        .wnpf .price {
          font-weight: bold;
          margin-right: 5px;
          position: relative;
          display: flex;
        }
        .wnpf .price .currency {
          font-size: 24px;
        }
        .wnpf .price .whole {
          font-size: 24px;
        }
        .wnpf .price .decimal {
          font-size: 14px;
          top: 0.2em;
          position: relative;
        }
        .wnpf .original {
          font-size: 7px;
          color: grey;
          font-weight: bold;
          margin-right: 5px;
          position: relative;
          display: flex;
        }
        .wnpf .original:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          border-top: 2px solid grey;
          background: grey;
          width: 100%;
          transform: translateY(-50%);
        }
        .wnpf .original .currency {
          font-size: 24px;
        }
        .wnpf .original .whole {
          font-size: 24px;
        }
        .wnpf .original .decimal {
          font-size: 14px;
          top: 0.2em;
          position: relative;
        }
        .wnpf img {
          float: left;
          width: 90%;
          margin-right: 10px;
          padding-right: 10px;
        }
        .wnpf-minimised {
          border-radius: 100px;
          padding: 10px 20px;
        }
        .wnpf p {
          margin: 10px 10px 0 10px;
        }
        .wnpf .search {
          border: 1px solid #222;
          margin: 5px;
          padding: 0.375rem 0.75rem;
          line-height: 1.5;
          outline: none;
        }

        #parts-finder-loading::before {
          content: 'Loading parts information…';
        }

        #parts-finder-help::before {
          content: "Can't find what you are looking for? Try the search bar above.";
        }

        #parts-finder-error::before {
          content: 'There has been a problem. please refresh the page to try again.';
        }
      </style>

      ${content()}
    `
  }
})

def('seeker-fitments-finder', { props: ['stylesheet', 'sku'] }, props => {
  let validated = false
  let fits_in = null
  let product_sku = null

  const state = ref({
    state: 'minimised',
    validated: false
  })

  const validate_product = async () => {
    const res = await auth_fetch(
      `${process.env.SEEKER_API}/v1/models/product/validate/${encodeURIComponent(product_sku)}/`
    )
    const { status } = await res.json()
    sku_validated(status)
  }

  const load_models = async () => {
    const res = await auth_fetch(`${process.env.SEEKER_API}/v1/models/product/${encodeURIComponent(product_sku)}/`)
    if (res.status !== 200 && res.status !== 304) {
      throw `Failed to load models for ${product_sku}`
    }
    fits_in = await res.json()
  }

  const maximise = prevent(async () => {
    try {
      state.state = 'loading'
      await load_models()
      state.state = 'default'
    } catch (e) {
      console.error(e)
      state.state = 'error'
    }
  })

  const minimise = prevent(async () => {
    state.state = 'minimised'
  })

  const set_sku = async sku => {
    product_sku = sku
  }

  const sku_validated = async valid => {
    state.validated = valid
  }

  return () => {
    set_sku(props.sku)
    if (!validated && product_sku !== undefined) validate_product(product_sku)

    const content = () => {
      if (state.validated) {
        if (state.state == 'minimised')
          return html`
          <div class="sff sff-minimised" @click="${maximise}">
            <a href="#" style="text-decoration: none;"><h3>Find Fitments</h3></a>
            <a class="close" href="#" style="text-decoration: none;">&#8964</a>
          </div>
        `
        else if (state.state == 'loading')
          return html`
            <div class="sff sff-loading">
              <nav>
                <div>
                  <h3>Fitments</h3>
                </div>
                <hr />
              </nav>
              <article>
                <span class="loading message" id="fitments-finder-loading"></span>
              </article>
            </div>
          `
        else if (state.state == 'error')
          return html`
            <div class="sff sff-error">
              <nav>
                <div>
                  <h3>Fitment</h3>
                </div>
                <hr />
              </nav>
              <article>
                <span class="loading message" id="fitments-finder-error"></span>
              </article>
            </div>
          `
        else if (state.state == 'default')
          return html`
            <div class="sff sff-default">
              <nav>
                <div>
                  <h3>Fitment</h3>
                  <a class="close" @click="${minimise}" href="#" style="text-decoration: none;">✕</a>
                </div>
                <hr />
              </nav>
              <article>
                <div>
                  ${fits_in && fits_in.length > 0
                    ? html`
                        ${fits_in.map(
                          make => html` <h4>${make.name}</h4>
                            <ul>
                              ${make.models.map(
                                model => html` <li>${model.name} - <small>(${model.years.join(', ')})</small></li> `
                              )}
                            </ul>`
                        )}
                      `
                    : html`<i>No fitments found for this product.</i>`}
                </div>
              </article>
            </div>
          `
      }
    }

    return html`
      ${props.stylesheet ? html`<link rel="stylesheet" href="${props.stylesheet}" type="text/css" />` : null}
      <style>
        seeker-fitments-finder-root' {
        }
        .sff {
          border: 2px solid #000;
          padding: 10px 15px;
          margin: 10px;
          display: flex;
          flex-direction: column;
        }
        .sff-minimised {
          border: 2px solid #000;
          padding: 10px 15px;
          margin: 10px 0;
          cursor: pointer;
          display: flex;
          justify-content: space-between;
        }
        .sff-minimised a h3 {
          margin-top: 0;
          margin-bottom: 0;
        }
        .sff-loading {
          border: 2px solid #000;
          padding: 10px 15px;
          margin: 10px 0;
          display: flex;
          flex-direction: column;
        }
        .sff-loading nav {
          display: flex;
          flex-direction: column;
        }
        .sff-loading nav div {
          display: flex;
          justify-content: space-between;
        }
        .sff-loading nav div h3 {
          margin-bottom: 0;
          margin-top: 0;
        }
        .sff-loading nav hr{
          border-bottom: solid 1px #000;
          margin: 15px 0;
        }
        .sff .loading {
          margin-left: auto;
          text-transform: uppercase;
        }
        .sff-default {
          border: 2px solid #000;
          padding: 10px 15px;
          margin: 10px 0;
          display: flex;
          flex-direction: column;
        }
        .sff-default h3 {
          margin-top: 0;
          margin-bottom: 0;
        }
        .sff-default nav {
          display: flex;
          flex-direction: column;
        }
        .sff-default nav div {
          display: flex;
          justify-content: space-between;
        }
        .sff-default nav hr{
          border-bottom: solid 1px #000;
          margin: 15px 0;
        }
        .sff-default article {
          max-height: 500px;
          overflow-y: auto;
          overflow-x: hidden;
        }
        .sff-default article div {
          word-wrap: break-word;
          padding-right: 30px;
        }

        #fitments-finder-loading::before {
          content: "Looking for models that fit this product..."
        }

        #fitments-finder-error::before {
          content: "There has been a problem. please refresh the page to try again. "
        }
      </style>

      ${content()}
    `
  }
})
